<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Status',
      'Payment': 'Pagamento',
      'Details': 'Detalhes',
      'Pending': 'Pendente',
      'Approved': 'Aprovado',
      'Canceled': 'Cancelado',
      'View': 'Ver',
    },
    es: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Estado',
      'Payment': 'Pago',
      'Details': 'Detalles',
      'Pending': 'Pendiente',
      'Approved': 'Aprobado',
      'Canceled': 'Cancelado',
      'View': 'Ver',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      table: {
        heade: [
          'Order', 'Date', 'Total', 'Status', ''
        ],
        body: null,
      },
    };
  },
  methods: {
    getOrders() {
      this.table.body = null

      api
        .get('store/orders')
        .then(response => {
          if (response.data.status == 'success') {
            this.table.body = response.data.list
          }
        })
        .catch(error => {
          if (error) {
            this.table.body = []
          }
        })
    },
  },
  mounted() {
    this.getOrders()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Orders') }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!table.body" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  <div v-if="row === 'id'">
                    <strong>#{{ data }}</strong>
                  </div>
                  <div v-else-if="row === 'total'">
                    {{ data | currency }}
                  </div>
                  <div v-else class="notranslate">
                    {{ data }}
                  </div>
                </td>
                <td class="text-right">
                  <router-link :to="'/store/order/' + td.id" class="btn btn-default btn-sm btn-rounded">
                    {{ t('View') }}
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>